import React from "react";
import crumbsLogo from "../../../assets/imgaes/crumbs-logo.png";
import searchIcon from "../../../assets/imgaes/search-icon.svg";
import plusIcon from "../../../assets/imgaes/plus-icon-aqua.svg";
import chatIcon from "../../../assets/imgaes/chat-icon.svg";
import UserIcon from "../../../assets/imgaes/user-icon.svg";

function Header() {
  return (
    <div className="header-wrap">
      <div className="project-logo">
        <img className="crumb-logo" src={crumbsLogo} alt="project-logo" />
      </div>
      <div className="global-serach-wrap">
        <div className="form-group">
          <img src={searchIcon} alt="search-icon" />
          <input
            type="text"
            className="form-control"
            placeholder="Search campaign, people or keywords"
          />
        </div>
      </div>
      <div className="user-section">
        <button className="secondary-btn">
          {" "}
          <img src={plusIcon} alt="plus-icon" /> New campaign
        </button>
        <button className="chat-icon">
          <img src={chatIcon} alt="chat-icon" />{" "}
        </button>

        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={UserIcon} alt="user-icon" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" href="#">
                Profile
              </a>
            </li>

            <li>
              <a class="dropdown-item" href="#">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
