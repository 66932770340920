import React from "react";
import socialIcon1 from "../../../assets/imgaes/instagram.svg";
import socialIcon2 from "../../../assets/imgaes/twitter.svg";
import socialIcon3 from "../../../assets/imgaes/linkden.svg";

function Footer() {
  return (
    <div className="footer-wrap">
      <ul className="social-icons">
        <li>
          <a href="javascript:void(0);">
            {" "}
            <img src={socialIcon1} alt="insta-icon" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            {" "}
            <img src={socialIcon2} alt="twitter-icon" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            {" "}
            <img src={socialIcon3} alt="linkden-icon" />
          </a>
        </li>
      </ul>

      <div className="terms-links-wrap">
        <ul className="terms-links tab-link-1">
          <li>
            <a href="javascript:void(0);">Terms of use</a>
          </li>
          <li>
            <a href="javascript:void(0);">Privacy Policy</a>
          </li>
          <li>
            <a href="javascript:void(0);">Cookie Policy</a>
          </li>
        </ul>
        <ul className="terms-links tab-link-2">
          <li>
            <a href="javascript:void(0);">
              Do Not Sell MY Personal Information
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">2024 Crumbs, All Right Reserved</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
