import React from "react";
import sideIcon1 from "../../../assets/imgaes/globe-icon.svg";
import sideIcon2 from "../../../assets/imgaes/play-circle-icon.svg";
import toggleIcon from "../../../assets/imgaes/toggle-icon.svg";
function Sidebar() {
  return (
    <div className="sidebar-wrap">
      <img className="toggle-icon" src={toggleIcon} alt="toggle-logo" />{" "}
      <ul>
        <li>
          <img className="crumb-logo" src={sideIcon1} alt="project-logo" />{" "}
          <span>Market</span>
        </li>
        <li>
          <img className="crumb-logo" src={sideIcon2} alt="project-logo" />{" "}
          <span>Feed</span>
        </li>
      </ul>
      <div className="sub-menus">
        <h2> Campaign Timing</h2>

        <div className="custom-radio-btton">
          <input type="radio" id="feed1" name="feed-group" />
          <label for="feed1">All</label>
        </div>
        <div className="custom-radio-btton">
          <input type="radio" id="feed2" name="feed-group" />
          <label for="feed2">Just launched</label>
        </div>
        <div className="custom-radio-btton">
          <input type="radio" id="feed3" name="feed-group" />
          <label for="feed3">Ending soon</label>
        </div>
        <div className="custom-radio-btton">
          <input type="radio" id="feed4" name="feed-group" />
          <label for="feed4">Ended</label>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
