import logo from './logo.svg';
import './App.scss';
import './styles/style.scss'
import Routing from './router/Routing';

function App() {
  return (
    <div className="App">
     <Routing/>
    </div>
  );
}

export default App;
