import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "../views/auth/Home";

const Routing = () => {
  return (
    <div>
      <HashRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
        </Routes>
      </HashRouter>
    </div>
  )
}

export default Routing