import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Sidebar from "./common/Sidebar";
import productImg1 from "../../assets/imgaes/product-img-1.svg";
import productImg2 from "../../assets/imgaes/product-img-2.svg";
import productImg3 from "../../assets/imgaes/product-img-3.svg";
import productImg4 from "../../assets/imgaes/product-img-4.svg";
import productImg5 from "../../assets/imgaes/product-img-5.svg";
import rangeIcon from "../../assets/imgaes/range-ceumbs.svg";
import usserImage from "../../assets/imgaes/user-image.svg";

const Home = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="maian-comtent-wrap">
          <h2>Support your campaigns</h2>

          <div className="campaigns-card-wrap">
            <div className="row">
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg1}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg2}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg3}
                      alt="product-image"
                    />
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg4}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg5}
                      alt="product-image"
                    />
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg1}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    <img
                      className="product-img"
                      src={productImg2}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="campaigns-card">
                  <div className="product-img-box">
                    {" "}
                    <img
                      className="product-img"
                      src={productImg3}
                      alt="product-image"
                    />{" "}
                  </div>

                  <p className="heading-tag">Herb Hairspray</p>
                  <div className="price-wrap">
                    <p>Starting at</p>
                    <span>$14.99</span>
                  </div>
                  <div class="progress-wrap">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >  <img
                      className="range-icon-progress"
                      src={rangeIcon}
                      alt="range-icon"
                    /></div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <p>99%</p> <span>funded</span>
                    </li>
                    <li>
                      {" "}
                      <p>190</p> <span>backers</span>
                    </li>
                    <li>
                      {" "}
                      <p>21 days</p> <span> left to go</span>
                    </li>
                  </ul>
                  <div className="user-detail-card">
                    <img
                      className="user-img"
                      src={usserImage}
                      alt="product-image"
                    />
                    <p> @Dave_builds</p>
                    <span>Builder Inc. | 3 parojects</span>
                  </div>
                  <button className="primary-btn">Support</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
